import { ConfigProvider } from "antd";
import { CustomThemeProvider } from "customHooks/ThemeContext";
import React, { lazy } from "react";
import { ThemeProvider } from "styled-components";
import LoaderGif from "assets/logo2.svg"

const AppRoutes = lazy(() => import("routes/Routes"))

function App() {
  return (
    <React.Suspense
      fallback={
        <div
          className="h-screen w-screen bg-white dark:bg-dark130 flex items-center justify-center"
        >
          <img src={LoaderGif} className="animate-fade" alt="Loading..." />
        </div>
      }
    >
      <CustomThemeProvider>
        <ThemeProvider
          theme={
            {
              primary: "#BA0000"
            }
          }>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#BA0000",
                colorPrimaryActive: "#BA0000",
              },
            }}
          >
            <AppRoutes />
          </ConfigProvider>
        </ThemeProvider>
      </CustomThemeProvider>
    </React.Suspense>
  );
}

export default App;
